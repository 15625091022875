<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Objet / sous-objet" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail</div>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">libellé</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="label" clearable></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize mt-2">
                    les activités
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <div v-for="(activity, i) in activities" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ activity.digitalName }}
                      </div>

                      <v-btn
                        icon
                        color="primary"
                        @click="removeActivity(activity)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedActivity"
                        :items="availableActivities"
                        item-text="digitalName"
                        return-object
                        placeholder="associer une activité"
                        hint="Associer une activité puis appuyer sur le bouton '+' ou sur 'entrée'"
                        clearable
                        no-data-text="aucune activité"
                        v-on:keyup.enter="addActivity(selectedActivity)"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addActivity(selectedActivity)"
                        :disabled="!selectedActivity"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize mt-2">
                    les sous-objets
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <div v-for="(subobject, i) in subobjects" :key="i">
                    <v-row no-gutters align="center" justify="space-between">
                      <v-text-field
                        v-model="subobject.label"
                        class="my-0 py-0"
                      ></v-text-field>

                      <v-btn
                        icon
                        color="primary"
                        @click="removeSubobject(subobject)"
                        class="pb-2"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-text-field
                        label="ajouter un sous-objet"
                        v-model="selectedSubobject"
                        v-on:keyup.enter="addSubobject(selectedSubobject)"
                        hint="Saisir le sous-objet puis appuyer sur le bouton '+' ou sur 'entrée'"
                      ></v-text-field>
                      <v-btn
                        color="primary"
                        icon
                        @click="addSubobject(selectedSubobject)"
                        :disabled="
                          !selectedSubobject ||
                          this.subobjectisNotUnique(selectedSubobject)
                        "
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <!-- <v-card-text>              
              <v-alert type="info" border="left" text
                ><div style="opacity: 1">
                  Pour être utilisable dans l'application, un objet doit avoir
                  un <span :class="classForLabel">libellé</span>, être relié à
                  <span :class="classForActivities">une ou plusieurs </span
                  >activités et définir un ensemble de
                  <span :class="classForSubobjects">sous-objets</span>.
                </div></v-alert
              >
            </v-card-text> -->

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { ObjectsService } from "@/service/conf/transmission/objects_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditTransmission",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /** l'identifiant à éditer */
      itemId: null,

      /**La donnée originale */
      source: null,

      /**le libellé */
      label: null,

      /**la liste des activités définies dans le SI */
      allActivities: [],

      /**les activités associées à l'objet */
      activities: [],

      /**l'activité sélectionnée pour l'ajout dans la liste */
      selectedActivity: null,

      /**les activités originales */
      initialActivities: [],

      /**les sous-objets associés à l'objet */
      subobjects: [],

      /**le sous-objet sélectionné pour l'ajout dans la liste */
      selectedSubobject: null,

      /**les sous-objets originaux */
      initialSubobjects: [],

      /**Les règles de validation */
      rules: {
        /**le sous-objet doit être unique. Contrainte supprimée du développement le 09/12/2020 par SR.   */
        unique: (value) =>
          !this.subobjectisNotUnique(value) || "ce sous-objet existe déjà.",
      },
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.allActivities = await this.service.getActivities();

        let item = await this.service.loadObjectById(this.itemId);

        //on ajoute les activités
        for (let activity of item.activities) {
          this.activities.push(activity);
        }

        //on garde la liste initiale des activités
        this.initialActivities = [...this.activities];

        for (let subobject of item.subobjects) {
          this.subobjects.push(subobject);
        }

        this.initialSubobjects = JSON.parse(JSON.stringify(this.subobjects));

        this.source = JSON.parse(JSON.stringify(item));

        this.init();

        // console.log(JSON.stringify(this.metier, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    init() {
      this.label = this.source.label;
    },
    async save() {
      try {
        let element = { id: this.itemId };
        element.label = this.label;

        //on enregistre l'objet en lui-même
        await this.service.update(element);

        //on met à jour les activités
        await this.service.updateActivities(
          this.itemId,
          this.initialActivities,
          this.activities
        );

        //on met à jour les sous-objets
        await this.service.updateSubobjects(
          this.itemId,
          this.initialSubobjects,
          this.subobjects
        );

        //on retourne sur la vue table
        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Retirer une activité */
    removeActivity(activity) {
      let index = this.activities.findIndex((r) => r.id == activity.id);
      this.activities.splice(index, 1);
    },

    /** Ajouter une activité */
    addActivity(activity) {
      if (!activity) return;

      this.activities.push(activity);

      this.selectedActivity = null;
    },

    /** Retirer un sous-objet */
    removeSubobject(subobject) {
      let index = this.subobjects.findIndex((r) => r == subobject);
      this.subobjects.splice(index, 1);
    },

    /** Ajouter un sous-objet */
    addSubobject(label) {
      if (!label) return;
      // if (this.subobjectisNotUnique(label)) return;  //contrainte retirée

      this.subobjects.push({ id: null, label });

      this.selectedSubobject = null;
    },

    /** Retourne true si un sous-objet est déjà présent dans la liste */
    subobjectisNotUnique(label) {
      return !!this.subobjects.find((o) => o.label === label);
    },
    foo() {
      this.subobjects[0].label = "i changed";
    },
  },
  computed: {
    /**Seul le libellé est obligatoire */
    completed() {
      if (!this.label) return false;

      if (this.activities.length === 0) return false;

      return true;
    },
    /** Retourne true si les données ont changés. */
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      if (!areTheSame(this.source.label, this.label)) {
        return true;
      }

      if (this.activitiesHaveChanged) {
        return true;
      }

      if (this.subobjectsHaveChanged) {
        return true;
      }

      return false;
    },

    /**Filtre sur les activités sélectionnables.*/
    availableActivities() {
      let availables = [];

      let ids = this.activities.map((r) => r.id);

      availables = this.allActivities.filter((r) => !ids.includes(r.id));

      return availables;
    },

    /**retourne true si les activités ont changés */
    activitiesHaveChanged() {
      return this.service.activitiesHaveChanged(
        this.initialActivities,
        this.activities
      );
    },

    /**retourne true si les sous-objets ont changés */
    subobjectsHaveChanged() {
      return this.service.subobjectsHaveChanged(
        this.initialSubobjects,
        this.subobjects
      );
    },

    classForLabel() {
      return this.label ? "green--text" : "";
    },

    classForActivities() {
      return this.activities.length > 0 ? "green--text" : "";
    },

    classForSubobjects() {
      return this.subobjects.length > 0 ? "green--text" : "";
    },
  },
  mounted() {
    this.service = new ObjectsService(
      this.$api.getTransmissionObjectsApi(),
      this.$api.getActivitiesApi()
    );

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.itemId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>